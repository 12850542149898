import { Component } from "react";
import { Link } from "react-router-dom";

export default class CourseDescription extends Component {
  render() {
    return (
      <div className="course">
        <img className="course-img" src={this.props.sections[0].keyframe} />
        <div className="course-info">
          <span className="h1">{this.props.courseTitle}</span>
          <span className="course-desc">{this.props.courseDescription}</span>
          <Link
            to={
              "/classes/" +
              this.props.courseTitle.replace(/\s+/g, "-").toLowerCase()
            }
          >
            {" "}
            <span className="watch-button">Watch Class</span>{" "}
          </Link>
        </div>
      </div>
    );
  }
}
