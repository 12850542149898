import { Component } from "react";

export default class ShareButton extends Component {
  render() {
    const uri = encodeURIComponent(this.props.shareUrl);
    return (
      <div className="share-container">
        <div className="modal hidden">
          <span className="text">Share Class</span>
          <a href={"mailto:?body=" + uri} target="_blank">
            <span className="email"></span>
          </a>
          <a
            href={"https://twitter.com/intent/tweet?text=" + uri}
            target="_blank"
          >
            <span className="twitter"></span>
          </a>
          <a
            href={"https://www.facebook.com/sharer/sharer.php?u=" + uri}
            target="_blank"
          >
            <span className="facebook"></span>
          </a>
          <a href={"mailto:?body=" + uri}>
            <span className="instagram"></span>
          </a>
          <a
            href={"https://www.linkedin.com/shareArticle?mini=true&url=" + uri}
            target="_blank"
          >
            <span className="linked-in"></span>
          </a>
          <input type="text" id="shareurl" value={this.props.shareUrl} />
          <button value="copy" className="copy" />
        </div>
        <a href="#">
          <div className="shareButton"></div>
        </a>
      </div>
    );
  }
}
