import { Component, createRef, useEffect, useRef, useState } from "react";
import data from "./../../constants/data.json";

import Slide from "../Slide";
import VideoSlide from "../VideoSlide";
import ShareButton from "../ShareButton";
import React from "react";

const CourseContent = () => {
  const carouselItemsScrollContainerRef = useRef();
  const videoSlideRef = useRef();
  const videoSlideElementsRef = useRef(data[0].sections.map(() => createRef()));

  const [activeSlideIndex, setActiveSlideIndex] = useState({
    current: 0,
    max: data[0].sections.length,
  });
  const [currentVideoUrl, setCurrentVideoUrl] = useState(
    data[0].sections[0].videoUrl
  );
  const [currentVideoTitle, setCurrentVideoTitle] = useState(
    data[0].sections[0].title
  );

  useEffect(() => {
    // if (carouselItemsContainerRef.current) {
    //   carouselItemsContainerRef.current.addEventListener(
    //     "wheel",
    //     preventWheelScroll,
    //     { passive: false }
    //   );
    //   carouselItemsContainerRef.current.addEventListener(
    //     "keydown",
    //     preventKeyBoardScroll,
    //     { passive: false }
    //   );
    // }
    // return () => {
    //   carouselItemsContainerRef.current.removeEventListener(
    //     "wheel",
    //     preventWheelScroll
    //   );
    //   carouselItemsContainerRef.current.removeEventListener(
    //     "keydown",
    //     preventKeyBoardScroll
    //   );
    // };
  }, []);

  const focusMainPlayer = () => {
    const elementPosition = videoSlideRef.current.getBoundingClientRect().top;
    const headerOffset = 100;
    const offsetPos = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPos,
      behavior: "smooth",
    });
  };

  const updateActiveSlideIndex = (newActiveSlideIndex) => {
    setActiveSlideIndex({
      current: newActiveSlideIndex,
      max: data[0].sections.length,
    });
  };

  const handleClick = (i) => {
    setActiveSlideIndex((prev) => ({ ...prev, current: i }));
    setCurrentVideoUrl(data[0].sections[i].videoUrl);
    setCurrentVideoTitle(data[0].sections[i].title);
    console.log("Playing:", data[0].sections[i].videoUrl);
  };

  const preventWheelScroll = (e) => {
    // If shift pressed, prevent default to prevent horizontal scrolling.
    if (e.shiftKey) {
      e.preventDefault();
    }
    e.stopPropagation();

    return false;
  };

  const preventKeyBoardScroll = (e) => {
    e.preventDefault();
    e.stopPropagation();
    var keys = [32, 33, 34, 35, 37, 38, 39, 40];
    if (keys.includes(e.keyCode)) {
      e.preventDefault();
      return false;
    }
  };

  const onVideoSelect = (idx) => {
    updateActiveSlideIndex(idx);

    handleGoTo(videoSlideElementsRef.current[idx].current.offsetLeft);
    focusMainPlayer();
  };

  // Mobile
  const cards = data[0].sections.map((s, i) => {
    return (
      <Slide
        className="slide"
        {...s}
        handleSelectVideo={onVideoSelect}
        onClick={handleClick.bind(this, i)}
        slideNum={i}
        keyframe={`/assets/images/key${i + 1}.png`}
      />
    );
  });

  // Desktop
  const slides = data[0].sections.map((s, i) => {
    return (
      <Slide
        slideRef={videoSlideElementsRef.current[i]}
        className="slide"
        {...s}
        handleSelectVideo={onVideoSelect}
        onClick={handleClick.bind(this, i)}
        slideNum={i}
        keyframe={`/assets/images/key${i + 1}.png`}
      />
    );
  });

const thumbnails = data[0].sections.map((s, i) => `/assets/images/key${i + 1}.png`);

  const playerOptions = {
    src: currentVideoUrl,
    controls: true,
    autoplay: "play",
  };

  const playNextVideo = () => {
    setActiveSlideIndex((prev) => {
      const nextIndex = prev.current + 1;
      const maxIndex = data[0].sections.length;
      if (prev.current + 1 === data[0].sections.length) return prev;
      return {
        current: nextIndex,
        max: maxIndex,
      };
    });
  };

  const handleVideoEnded = (e) => {
    playNextVideo();
  };

  const handleGoBackward = (e) => {
    setActiveSlideIndex((prev) => {
      if (prev.current === 0) return prev;
      return { ...prev, current: prev.current - 1 };
    });
  };
  const handleGoForward = (e) => {
    setActiveSlideIndex((prev) => {
      if (prev.current === prev.max - 1) return prev;
      return { ...prev, current: prev.current + 1 };
    });
  };

  const handleGoTo = (pos) => {
    carouselItemsScrollContainerRef.current.scrollLeft = pos;
  };

  useEffect(() => {
    handleGoTo(
      videoSlideElementsRef.current[activeSlideIndex.current].current.offsetLeft
    );
    setCurrentVideoUrl(data[0].sections[activeSlideIndex.current].videoUrl);
    setCurrentVideoTitle(data[0].sections[activeSlideIndex.current].title);
    console.log(
      "Playing:",
      data[0].sections[activeSlideIndex.current].videoUrl
    );
  }, [activeSlideIndex]);

  return (
    <div className="video-player-container">
      <VideoSlide
        activeSlideIndex={activeSlideIndex}
        videoPlayerRef={videoSlideRef}
        playerOptions={playerOptions}
        videoSrc={currentVideoUrl}
        videoUrl={currentVideoUrl}
        videoTitle={currentVideoTitle}
        thumbnails={thumbnails}
        shareUrl={"https://blackbeautyroster.com/"}
        onGoBackward={handleGoBackward}
        onGoForward={handleGoForward}
        onEnded={handleVideoEnded}
      />

      <div className="carousel-container">
        <div className="scroll-container" ref={carouselItemsScrollContainerRef}>
          <div className="carousel-items" tabIndex="0">
            {slides}
          </div>
        </div>
      </div>
      <div className="mobile-slides-container">{cards}</div>
    </div>
  );
};

export default CourseContent;
