import { LazyLoadImage } from 'react-lazy-load-image-component';

const Slide = (props) => {
  const slideStyle = {
    position: "relative",
    backgroundSize: "100%",
    marginBottom: "30px",
  };

  const containerStyle = {
    position: "relative",
  };
  const watchStyle = {
    margin: "75px",
  };
  const titleStyle = {
    color: "white",
    fontFamily: "Century Gothic",
    fontSize: "20px",
  };
  const subDescStyle = {
    // color: "white",
    marginTop: "13px",
    wordWrap: "break-word",
    whiteSpace: "normal",
  };
  const defaultDesc = "";
  return (
    <div
      className="slide-container cursor-pointer"
      onClick={(e) => props.handleSelectVideo(props.slideNum)}
    >
      <div className="slide" style={slideStyle} ref={props.slideRef}>
        <div className="slide-thumbnail-container">
        <LazyLoadImage
          src={props.keyframe}
          effect="blur"
          width="100%"
          className="slide-thumbnail"
      />
          {/* <img
            src={props.keyframe}
            alt="slide-thumbnail"
            className="slide-thumbnail"
          ></img> */}
        </div>
        <div className="slide-description">
          <span className="title">{props.title}</span>
          <p style={subDescStyle} className="description">
            {props.description || defaultDesc}
          </p>
        </div>
      </div>

      <div className="watch-button-container">
        <span style={watchStyle} className="watch">
          <p>
          Watch Session
          </p>
        </span>
      </div>
    </div>
  );
};

export default Slide;
