import copy from "copy-to-clipboard";
import { Component } from "react";
import ReactPlayer from "react-player";
import isIos from "../../helpers/isIos";
import "./video-slide.css";

export default class VideoSlide extends Component {
  constructor() {
    super();
    this.state = { key: "value", showShareModal: false };
  }

  render() {
    const videoJsOptions = {
      autoplay: true,
      controls: true,
      width: "100%",
      height: "100%",
      sources: [
        {
          src: this.props.videoUrl,
          type: "video/mp4",
        },
      ],
    };

    const handleToggleShareModal = (e) => {
      this.setState((state) => ({
        ...state,
        showShareModal: !this.state.showShareModal,
      }));
    };

    const handleCopy = (e) => {
      if (isIos()) {
        copy(window.location.href);
      } else {
        navigator.clipboard.writeText(window.location.href);
      }
    };

    return (
      <div className="video-player-container" ref={this.props.videoPlayerRef}>
        <div id="video-player">
          <ReactPlayer
            width="100%"
            height="100%"
            controls={true}
            muted={false}
            playing={true}
            light={`${window.location.origin}${this.props.thumbnails[this.props.activeSlideIndex.current]}`}
            url={this.props.videoUrl}
            playsinline
            onEnded={this.props.onEnded}
            file={{ forceHLS: true }}
          />
        </div>
        <div className="video-jaw-container">
          <div className="video-name">
            <span>{this.props.videoTitle ?? ""}</span>
          </div>
          <div className="share-container">
            <span
              className="share-modal-toggler cursor-pointer"
              onClick={handleToggleShareModal}
            >
              SHARE
            </span>
            <div
              className={`modal-share ${
                this.state.showShareModal ? "" : "hidden"
              }`}
            >
              <span className="text">Share Class</span>
              <div className="icons-container">
                <a href="mailto:hello@blackbeautyroster.com">
                  <span className="email"></span>
                </a>
                <a href={`https://twitter.com/intent/tweet?text=I'm watching Sir John's class on ${window.location.origin}!`}>
                  <span className="twitter"></span>
                </a>
                <a href={`https://www.facebook.com/sharer/sharer.php?u=I'm watching Sir John's class on ${window.location.origin}!`}>
                  <span className="facebook"></span>
                </a>
                <a href="https://www.instagram.com/blackbeautyroster/?hl=en">
                  <span className="instagram"></span>
                </a>
                <a href={`https://www.linkedin.com/shareArticle?mini=true&amp;url=I'm watching Sir John's class on ${window.location.origin}!`}>
                  <span className="linked-in"></span>
                </a>
              </div>
              <div className="share-link-container">
                <input type="text" id="shareurl" value={window.location.href} />
                <button value="copy" className="copy" onClick={handleCopy}>
                  copy
                </button>
                <a href="https://blackbeautyroster.com/classes/#">
                  <div className="shareButton"></div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="carousel-controls">
          <button
            className="backward cursor-pointer"
            type="button"
            onClick={this.props.onGoBackward}
          ></button>
          <button
            className="forward cursor-pointer"
            type="button"
            onClick={this.props.onGoForward}
          ></button>
        </div>
      </div>
    );
  }
}
