import logo from "./logo.svg";
import "./App.css";
import React, { Component } from "react";
import Carousel from "react-simply-carousel";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useSearchParams,
} from "react-router-dom";
import ReactPlayer from "react-player";
import data from "./constants/data.json";
import CoursePage from "./components/CoursePage";
import Courses from "./components/Courses";
import Navbar from "./components/Navbar";
import BBRNavbar from "./components/BBRNavbar";
import DocumentMeta from "react-document-meta";

const assetUrl =
  process.env.NODE_ENV === "local"
    ? "https://blackbeautyroster.com/wp-content/themes/blackbeautyroster/build"
    : "";

const meta = {
  title: "SIR JOHN BBR CLASS",
  description:
    "Learn more on what it takes to advance your career as a makeup artist Skillset. Culture. Impact",
  meta: {
    charset: "utf-8",
    name: {
      keywords: "bbr, sir john, john, sir, blackbeautyroster, class",
    },
  },
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { navBbrPortal: false };
  }

  componentDidMount() {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const source = params.get("source");
    const isBbrPortal = source === "bbrportal.com" ? true : false;

    this.setState({ navBbrPortal: isBbrPortal });
  }

  render() {
    const courseRoutes = data.map((c, i) => {
      return (
        <Route
          key={i}
          path={"/" + c.courseTitle.replace(/\s+/g, "-").toLowerCase()}
          element={<CoursePage {...c} />}
        />
      );
    });

    return (
      <DocumentMeta {...meta}>
        <div>
          {this.state.navBbrPortal && <BBRNavbar></BBRNavbar>}
          {!this.state.navBbrPortal && <Navbar></Navbar>}
          <CoursePage {...data[0]} />
          <footer>
            <div className="footer_detail">
              <h1>CONNECT WITH THE ROSTER</h1>
              <div className="social_icons">
                <ul>
                  <li>
                    <a
                      href="https://www.instagram.com/blackbeautyroster/?hl=en"
                      target="_blank"
                    >
                      <img src="/assets/images/instagram-icon.svg" alt="#" />
                    </a>
                  </li>
                  <li>
                    <a href="mailto:hello@blackbeautyroster.com">
                      <img src="/assets/images/email-icon-filled.svg" alt="#" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </footer>
        </div>
      </DocumentMeta>
    );
  }
}

export default App;
