import CourseContent from "../CourseContent";
import Header from "../Header";
import "./main.css";

const CoursePage = (props) => {
  return (
    <div className="masterclass-course-page">
      <Header
        headerImage={props.headerImg}
        shortTitle={props.headerTitle}
        shortDescription={props.headerDescription}
        shortDescriptionSub={props.subHeaderDescription}
      />
      <main>
        <span className="course-title">{props.courseTitle}</span>
        <p className="course-description">{props.courseDescription}</p>
        <CourseContent {...props} />
      </main>
    </div>
  );
};

export default CoursePage;
