import { Component, useEffect } from "react";
import "./header.css";

const Header = ({ headerImage, shortTitle, shortDescription, shortDescriptionSub }) => {
  return (
    <header>
      <img
        className="header-image"
        src={headerImage ?? "/assets/images/sir-john.png"}
        alt="header"
      />
      <div className="header-description-container">
        <span className="title">{shortTitle}</span>
        <span className="description">{shortDescription}</span>
        <span className="description-sub">{shortDescriptionSub}</span>
      </div>
    </header>
  );
};

export default Header;
