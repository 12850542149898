import { useEffect, useRef, useState } from "react";
import useScrollPosition from "@react-hook/window-scroll";
import "./navbar.css";

const Navbar = () => {
  const wrapperRef = useRef(null);
  const scrollY = useScrollPosition(60 /*fps*/);

  useEffect(() => {
    if (scrollY >= 10) {
      wrapperRef.current.classList.add("fixed_header");
    } else {
      wrapperRef.current.classList.remove("fixed_header");
    }
  }, [scrollY]);

  const [navMenuOpen, setNavMenuOpen] = useState(false);
  const handleMenuIconClick = () => {
    setNavMenuOpen(true);
  };

  const handleCloseNavClick = () => {
    setNavMenuOpen(false);
  };

  return (
    <div className="wrapper" ref={wrapperRef}>
      <div className="header_outer">
        <div className="auto_container">
          <div className="header_inner">
            <div className="logo">
              <a href="https://blackbeautyroster.com">
                <img
                  src="https://blackbeautyroster.com/wp-content/themes/blackbeautyroster/images/bonnti_logo.png"
                  alt="#"
                />
              </a>
            </div>

            <div className="menuIcon" onClick={handleMenuIconClick}></div>
            {/* <SlideDownWrapper open={navMenuOpen}> */}
            <div className={`menuNav ${navMenuOpen ? "open" : ""}`}>
              <div className="closeNav" onClick={handleCloseNavClick}></div>
              <ul id="menu-primary" className="main-nav">
                <li
                  id="menu-item-20312"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-20312"
                >
                  <a href="https://blackbeautyroster.com/join-the-roster/">
                    Join the Roster
                  </a>
                </li>
                <li
                  id="menu-item-1569"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1569"
                >
                  <a href="https://blackbeautyroster.com/bbr-summit-register/">
                    BBR summit
                  </a>
                </li>
                <li
                  id="menu-item-35"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-35"
                >
                  <a href="https://blackbeautyroster.com/bbr-accelarate/">
                    BBR ACCELERATE
                  </a>
                </li>
                <li
                  id="menu-item-38"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-38"
                >
                  <a href="https://blackbeautyroster.com/for-brands/">
                    For Brands
                  </a>
                </li>
                <li
                  id="menu-item-37"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-37"
                >
                  <a href="https://blackbeautyroster.com/education/">
                    EDUCATION
                  </a>
                </li>
                <li
                  id="menu-item-39"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-39"
                >
                  <a href="https://blackbeautyroster.com/mission/">MISSION</a>
                </li>
                <li
                  id="menu-item-36"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-36"
                >
                  <a href="https://blackbeautyroster.com/contact/">CONTACT</a>
                </li>
              </ul>
            </div>
            {/* </SlideDownWrapper> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
