import React from "react";
import "./BBRNavbar.scss";

const BBRNavbar = () => {
  const bbrportalURL = "https://bbrportal.com";

  return (
    <div className="navbar navbar-fixed-top navbar-inverse">
      <div className="container">
        <a id="header-logo" href={bbrportalURL}>
          <svg
            width="159"
            height="41"
            viewBox="0 0 159 41"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M41.7971 28.2021C41.7971 23.8571 40.2961 21.3291 36.9781 18.6431C39.1901 16.0361 39.7431 14.5351 39.7431 11.1381C39.7431 8.13612 38.6371 5.05513 36.4251 2.92212C33.7391 0.315123 30.8161 0.078125 27.2611 0.078125H0.559082V41.0001H27.2611C35.3981 41.0001 41.7971 36.8921 41.7971 28.2021ZM31.3691 11.6911C31.3691 14.7721 30.1051 15.3251 27.2611 15.3251H8.69608V8.29412H27.2611C29.7101 8.29412 31.3691 8.92612 31.3691 11.6911ZM33.6601 28.0441C33.6601 31.9941 30.5791 32.7841 27.2611 32.7841H8.69608V23.4621H27.2611C30.5001 23.4621 33.6601 24.1731 33.6601 28.0441Z"
              fill="black"
            ></path>
            <path
              d="M98.7727 28.2021C98.7727 23.8571 97.2718 21.3291 93.9538 18.6431C96.1658 16.0361 96.7188 14.5351 96.7188 11.1381C96.7188 8.13612 95.6127 5.05513 93.4007 2.92212C90.7147 0.315123 87.7917 0.078125 84.2367 0.078125H57.5347V41.0001H84.2367C92.3737 41.0001 98.7727 36.8921 98.7727 28.2021ZM88.3447 11.6911C88.3447 14.7721 87.0807 15.3251 84.2367 15.3251H65.6717V8.29412H84.2367C86.6857 8.29412 88.3447 8.92612 88.3447 11.6911ZM90.6357 28.0441C90.6357 31.9941 87.5547 32.7841 84.2367 32.7841H65.6717V23.4621H84.2367C87.4757 23.4621 90.6357 24.1731 90.6357 28.0441Z"
              fill="black"
            ></path>
            <path
              d="M158.434 41.0001L148.085 24.2521C153.773 22.4351 157.249 18.5641 157.249 12.4021C157.249 8.45213 155.432 4.58112 152.193 2.29012C149.665 0.473125 147.453 0.078125 144.372 0.078125H116.406V41.0001H124.701V24.2521H137.736L148.085 41.0001H158.434ZM148.954 12.1651C148.954 14.7721 146.821 16.1151 144.372 16.1151H124.701V8.29412H142.95C145.794 8.29412 148.954 8.53113 148.954 12.1651Z"
              fill="black"
            ></path>
          </svg>
        </a>
        <nav>
          <div className="navbar-header">
            <button
              type="button"
              className="navbar-toggle collapsed"
              data-toggle="collapse"
              data-target="#bs-example-navbar-collapse-1"
              aria-expanded="false"
            >
              <span className="sr-only">Toggle navigation</span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </button>
          </div>

          <ul
            className="nav navbar-nav navbar-right collapse navbar-collapse"
            id="bs-example-navbar-collapse-1"
          >
            <li className="navbar-link ">
              <a href={`${bbrportalURL}`}>
                <span className="head-nav-text">Mission</span>
              </a>
            </li>
            <li className="navbar-link  ">
              <a href={`${bbrportalURL}/jobs`}>
                <span className="head-nav-text">Job Board</span>
              </a>{" "}
            </li>
            {/* <li className="navbar-link  ">
              <a href={`${bbrportalURL}/score_intro`}>
                <span className="head-nav-text">BBR Score</span>
              </a>{" "}
            </li> */}
            <li className="navbar-sign-up-link">
              <a href={`${bbrportalURL}/signup`}>Sign up</a>
            </li>
            <li className="navbar-login-link">
              <a href={`${bbrportalURL}/login`}>Log in</a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default BBRNavbar;
